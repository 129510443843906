<template>
	<div id="dm_privacy" class="pt-5">
        <section class="pt-5 pb-2 pb-lg-5">
            <div class="pt-5 px-3 px-lg-5">
                <span class="mc--title">
                    AVISO DE PRIVACIDAD
                </span> <br>

                <!-- <i class="mc--description-sm">
                    Términos y condiciones del servicio MARCO ANTONIO VILLALOBOS ARROYO VIAM750 10 3Q29.
                </i> -->
            </div>
        </section>

        <!-- content -->
        <section class="container">
            <div class="row pb-5 px-3 px-lg-5">
                <div class="col-12 text-justify pb-5  mx-0">
                    
                    <span class="mc--description">

                        <br><br>

                        El presente documento constituye el Aviso de Privacidad para efectos de lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (la “LFPDP”) y las disposiciones que emanan de ella o se relacionan con la misma. Este Aviso de Privacidad (en lo sucesivo referido como “Aviso”) aplica a la información personal recopilada sobre el Titular por MARCO ANTONIO VILLALOBOS ARROYO VIAM750 10 3Q29.  en su carácter de Responsable. El presente Aviso tiene por objeto la protección de tus datos personales, mediante su tratamiento legítimo, controlado e informado, a efecto de garantizar su privacidad, así como tu derecho a la autodeterminación informativa. <br>
                        <br>

                        Conforme al artículo 3, fracción V, de la Ley, se entiende por Datos Personales: Cualquier información concerniente a una persona física identificada o identificable de conformidad a lo dispuesto por la fracción I del artículo 16 de la Ley, será el Responsable de tu información personal (Datos Personales).<br>
                        <br><br>
                        

                        <b>¿Qué información recopilamos?</b> <br>
                        <br>
                         
                        El Responsable recolecta información que puede identificarle de manera razonable, por ejemplo, a título enunciativo más no limitativo: su nombre y apellidos; fecha de nacimiento; dirección de correo electrónico, información general de su perfil. <br>
                        <br><br>

                        <b>¿Cómo usamos o compartimos la información que recopilamos?</b> <br>
                        <br>

                        Al enviar vía correo electrónico tus datos personales a los diferentes representantes de MARCO ANTONIO VILLALOBOS ARROYO VIAM750 10 3Q29.  y/o completar la solicitud en línea que aparece en la página, aceptas y autorizas a MARCO ANTONIO VILLALOBOS ARROYO VIAM750 10 3Q29.  utilizar y tratar de forma automatizada tus datos personales e información suministrados, los cuales formarán parte de nuestra base de datos con la finalidad de usarlos en forma enunciativa, más no limitativa para: identificarte, comunicarte, contactarte, enviarte información, actualizar nuestra base de datos, y promocionar todo el contenido artístico generado por MARCO ANTONIO VILLALOBOS ARROYO VIAM750 10 3Q29.  <br>
                        <br><br>

                        MARCO ANTONIO VILLALOBOS ARROYO VIAM750 10 3Q29. como responsable del tratamiento de tus datos personales, está obligado a cumplir con los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad tutelados en la Ley; por tal motivo con fundamento en los artículos 13 y 14 de la Ley MARCO ANTONIO VILLALOBOS ARROYO VIAM750 10 3Q29.  se compromete a guardar estricta confidencialidad de tus datos personales, así como a mantener las medidas de seguridad administrativas, técnicas y físicas que permitan protegerlos contra cualquier daño, pérdida, alteración, acceso o tratamiento no autorizado. <br>
                        <br><br>

                        <b>¿Cómo contactar a nuestro Departamento de Datos Personales?</b> <br>
                        <br>
                        
                        Para cualquier comunicación acerca de nuestro Aviso de Privacidad, por favor contacte a nuestro Departamento de Datos Personales: <a href="mailto:contacto@concretosmac-coy.com">contacto@concretosmac-coy.com</a> <br>

                        <br><br>

                    </span>
                </div>
            </div>
        </section>
	</div>
</template>

<script>
	export default {
		name: 'Terms',
		data() {
			return {

			}
	    }
	}
</script>

<style scoped lang="scss">
	#dm_privacy {
		padding: 0px;
	}
</style>
